import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },
  {
    path: '/dashboard/beta',
    Component: lazy(() => import('pages/dashboard/beta')),
    exact: true,
  },
  {
    path: '/dashboard/gamma',
    Component: lazy(() => import('pages/dashboard/gamma')),
    exact: true,
  },
  {
    path: '/dashboard/crypto',
    Component: lazy(() => import('pages/dashboard/crypto')),
    exact: true,
  },

  // Ecommerce
  {
    path: '/ecommerce/dashboard',
    Component: lazy(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    Component: lazy(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/product-catalog',
    Component: lazy(() => import('pages/ecommerce/product-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    Component: lazy(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    Component: lazy(() => import('pages/ecommerce/cart')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  // Extra Apps
  {
    path: '/apps/github-explore',
    Component: lazy(() => import('pages/apps/github-explore')),
    exact: true,
  },
  {
    path: '/apps/github-discuss',
    Component: lazy(() => import('pages/apps/github-discuss')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-droplets',
    Component: lazy(() => import('pages/apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-create',
    Component: lazy(() => import('pages/apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/apps/google-analytics',
    Component: lazy(() => import('pages/apps/google-analytics')),
    exact: true,
  },
  {
    path: '/apps/wordpress-post',
    Component: lazy(() => import('pages/apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/apps/wordpress-posts',
    Component: lazy(() => import('pages/apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/apps/wordpress-add',
    Component: lazy(() => import('pages/apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/apps/todoist-list',
    Component: lazy(() => import('pages/apps/todoist-list')),
    exact: true,
  },
  {
    path: '/apps/jira-dashboard',
    Component: lazy(() => import('pages/apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/apps/jira-agile-board',
    Component: lazy(() => import('pages/apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/apps/helpdesk-dashboard')),
    exact: true,
  },
  // Widgets
  {
    path: '/widgets/general',
    Component: lazy(() => import('pages/widgets/general')),
    exact: true,
  },
  {
    path: '/widgets/lists',
    Component: lazy(() => import('pages/widgets/lists')),
    exact: true,
  },
  {
    path: '/widgets/tables',
    Component: lazy(() => import('pages/widgets/tables')),
    exact: true,
  },
  {
    path: '/widgets/charts',
    Component: lazy(() => import('pages/widgets/charts')),
    exact: true,
  },
  // Cards
  {
    path: '/cards/basic-cards',
    Component: lazy(() => import('pages/cards/basic-cards')),
    exact: true,
  },
  {
    path: '/cards/tabbed-cards',
    Component: lazy(() => import('pages/cards/tabbed-cards')),
    exact: true,
  },
  // UI Kits
  {
    path: '/ui-kits/bootstrap',
    Component: lazy(() => import('pages/ui-kits/bootstrap')),
    exact: true,
  },
  {
    path: '/ui-kits/antd',
    Component: lazy(() => import('pages/ui-kits/antd')),
    exact: true,
  },
  // Tables
  {
    path: '/tables/bootstrap',
    Component: lazy(() => import('pages/tables/bootstrap')),
    exact: true,
  },
  {
    path: '/tables/antd',
    Component: lazy(() => import('pages/tables/antd')),
    exact: true,
  },
  // Charts
  {
    path: '/charts/chartistjs',
    Component: lazy(() => import('pages/charts/chartistjs')),
    exact: true,
  },
  {
    path: '/charts/chartjs',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  {
    path: '/charts/c3',
    Component: lazy(() => import('pages/charts/c3')),
    exact: true,
  },
  // Icons
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons')),
    exact: true,
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free')),
    exact: true,
  },
  {
    path: '/icons/icomoon-free',
    Component: lazy(() => import('pages/icons/icomoon-free')),
    exact: true,
  },
  // Advanced
  {
    path: '/advanced/form-examples',
    Component: lazy(() => import('pages/advanced/form-examples')),
    exact: true,
  },
  {
    path: '/advanced/email-templates',
    Component: lazy(() => import('pages/advanced/email-templates')),
    exact: true,
  },
  {
    path: '/advanced/utilities',
    Component: lazy(() => import('pages/advanced/utilities')),
    exact: true,
  },
  {
    path: '/advanced/grid',
    Component: lazy(() => import('pages/advanced/grid')),
    exact: true,
  },
  {
    path: '/advanced/typography',
    Component: lazy(() => import('pages/advanced/typography')),
    exact: true,
  },
  {
    path: '/advanced/pricing-tables',
    Component: lazy(() => import('pages/advanced/pricing-tables')),
    exact: true,
  },
  {
    path: '/advanced/invoice',
    Component: lazy(() => import('pages/advanced/invoice')),
    exact: true,
  },
  {
    path: '/advanced/colors',
    Component: lazy(() => import('pages/advanced/colors')),
    exact: true,
  },
  // Store-Settings
  // sms
  {
    path: '/storesettings/sms',
    Component: lazy(() => import('pages/StoreSettings/Sms/index')),
    exact: true,
  },
  {
    path: '/storesettings/mails',
    Component: lazy(() => import('pages/StoreSettings/Mails/index')),
    exact: true,
  },
  {
    path: '/storesettings/razorpaysettings',
    Component: lazy(() => import('pages/StoreSettings/RazorpaySettings/index')),
    exact: true,
  },
  {
    path: '/storesettings/subscription',
    Component: lazy(() => import('pages/StoreSettings/Subscription/index')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  //Edit User
  {
    path: '/user/useredit',
    Component: lazy(() => import('pages/user/EditProfile')),
    exact: true,
  },
  //Store FrontEnd
  //Image Gallery
  {
    path: '/storefrontend/imggallery',
    Component: lazy(() => import('pages/storeFrontEnd/imageGallery/index')),
    exact: true,
  },
  {
    path: '/storefrontend/imggalleryadd',
    Component: lazy(() => import('pages/storeFrontEnd/imageGallery/AddImage')),
    exact: true,
  },
  // Pagess
  {
    path: '/storefrontend/pagess',
    Component: lazy(() => import('pages/storeFrontEnd/Pages/Pagess')),
    exact: true,
  },
  {
    path: '/storefrontend/table',
    Component: lazy(() => import('pages/storeFrontEnd/Pages/Table')),
    exact: true,
  },
  // HomeSlide
  {
    path: '/storefrontend/homeslide',
    Component: lazy(() => import('pages/storeFrontEnd/HomeSlide/AddHomeSlide')),
    exact: true,
  },
  {
    path: '/storefrontend/homeslidetable',
    Component: lazy(() => import('pages/storeFrontEnd/HomeSlide/HomeSlideTable')),
    exact: true,
  },
  //OfferSlide
  {
    path: '/storefrontend/offerslide',
    Component: lazy(() => import('pages/storeFrontEnd/OfferSlide/AddOfferSlide')),
    exact: true,
  },
  {
    path: '/storefrontend/offerslidetable',
    Component: lazy(() => import('pages/storeFrontEnd/OfferSlide/OfferSlideTable')),
    exact: true,
  },
  //Testimonial
  {
    path: '/storefrontend/testimonials',
    Component: lazy(() => import('pages/storeFrontEnd/Testimonial')),
    exact: true,
  },
  // Contact
  {
    path: '/storefrontend/contact',
    Component: lazy(() => import('pages/storeFrontEnd/contact/index')),
    exact: true,
  },
  {
    path: '/storefrontend/addContact',
    Component: lazy(() => import('pages/storeFrontEnd/contact/addContact')),
    exact: true,
  },
  // FAQ
  {
    path: '/storefrontend/faq',
    Component: lazy(() => import('pages/storeFrontEnd/FAQ/index')),
    exact: true,
  },
  {
    path: '/storefrontend/addfaq',
    Component: lazy(() => import('pages/storeFrontEnd/FAQ/addFAQ')),
    exact: true,
  },
  //Blog
  //Blog categories
  {
    path: '/blog/category',
    Component: lazy(() => import('pages/blog/category/index')),
    exact: true,
  },
  {
    path: '/blog/addCategory',
    Component: lazy(() => import('pages/blog/category/AddCategory')),
    exact: true,
  },
  //Blog Sub categories
  {
    path: '/blog/subcategory',
    Component: lazy(() => import('pages/blog/subCategory/index')),
    exact: true,
  },
  {
    path: '/blog/addSubCategory',
    Component: lazy(() => import('pages/blog/subCategory/AddSubCategory')),
    exact: true,
  },
  //Blog
  {
    path: '/blog',
    Component: lazy(() => import('pages/blog/blog/index')),
    exact: true,
  },
  {
    path: '/blog/add',
    Component: lazy(() => import('pages/blog/blog/AddBlog')),
    exact: true,
  },
  //Event
  {
    path: '/event',
    Component: lazy(() => import('pages/blog/event/index')),
    exact: true,
  },
  {
    path: '/event/add',
    Component: lazy(() => import('pages/blog/event/AddEvent')),
    exact: true,
  },
  //LMS
  //LMS categories
  {
    path: '/lms/category',
    Component: lazy(() => import('pages/LMS/category/index')),
    exact: true,
  },
  {
    path: '/lms/addCategory',
    Component: lazy(() => import('pages/LMS/category/AddCategory')),
    exact: true,
  },
  //LMS Sub categories
  {
    path: '/lms/subcategory',
    Component: lazy(() => import('pages/LMS/subCategory/index')),
    exact: true,
  },
  {
    path: '/lms/addSubCategory',
    Component: lazy(() => import('pages/LMS/subCategory/AddSubCategory')),
    exact: true,
  },
  //LMS courses
  {
    path: '/lms/courses',
    Component: lazy(() => import('pages/LMS/courses/index')),
    exact: true,
  },
  {
    path: '/lms/addCourses',
    Component: lazy(() => import('pages/LMS/courses/AddCourses')),
    exact: true,
  },
  //LMS courses Section
  {
    path: '/lms/courseSection/:Id',
    Component: lazy(() => import('pages/LMS/courseSection/index')),
    exact: true,
  },
  {
    path: '/lms/addCourseSection/:Id',
    Component: lazy(() => import('pages/LMS/courseSection/AddCourseSection')),
    exact: true,
  },
  //LMS KEY HIGHLIGHTS
  {
    path: '/keyHighlight',
    Component: lazy(() => import('pages/LMS/keyHighlight/index')),
    exact: true,
  },
  {
    path: '/keyHighlight/add',
    Component: lazy(() => import('pages/LMS/keyHighlight/AddKeyHighlight')),
    exact: true,
  },
  //LMS Quiz
  {
    path: '/lms/quiz',
    Component: lazy(() => import('pages/LMS/Quiz/index')),
    exact: true,
  },
  {
    path: '/lms/addQuiz',
    Component: lazy(() => import('pages/LMS/Quiz/addQuiz')),
    exact: true,
  },
  //LMS Questions
  {
    path: '/lms/question',
    Component: lazy(() => import('pages/LMS/Question/index')),
    exact: true,
  },
  {
    path: '/lms/addQuestion',
    Component: lazy(() => import('pages/LMS/Question/addQuestion')),
  },
  //LMS lessons
  {
    path: '/lms/lessons/:Id',
    Component: lazy(() => import('pages/LMS/lessons/index')),
    exact: true,
  },
  {
    path: '/lms/addlessons/:Id',
    Component: lazy(() => import('pages/LMS/lessons/AddLessons')),
    exact: true,
  },
  //LMS USER
  {
    path: '/user',
    Component: lazy(() => import('pages/user/User/index')),
    exact: true,
  },
  {
    path: '/user/add',
    Component: lazy(() => import('pages/user/User/AddUser')),
    exact: true,
  },

  //ORDER
  //PROMOCODE
  {
    path: '/promocode',
    Component: lazy(() => import('pages/order/promocode/index')),
    exact: true,
  },
  {
    path: '/promocode/add',
    Component: lazy(() => import('pages/order/promocode/addCode')),
    exact: true,
  },
  //ORDER
  {
    path: '/order',
    Component: lazy(() => import('pages/order/order/index')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            console.log(state)
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/lms/category" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
