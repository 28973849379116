import { notification } from 'antd'
import axios from 'axios'
import unApi from '../global/unApi'
import setAuthToken from '../global/setAuthToken'

export async function login(contact1, password1) {
  const value = { email: contact1, password: password1 }
  let authorized1 = false
  return axios
    .post(`/api-token-auth/`, value, {
      type: 'json',
      headers: {
        Authorization: ``,
      },
    })
    .then(response => {
      authorized1 = true
      // const expirationDate = new Date(new Date().getTime() + 3600 * 10000)
      localStorage.setItem('token', response.data.token)
      // localStorage.setItem('expirationDate', expirationDate)

      setAuthToken(localStorage.getItem('token'))
      return {
        id: response.data.user.id,
        authorized: authorized1,
        contact: response.data.user.conatct,
        role: response.data.user.roles,
        email: response.data.user.email,
        token: response.data.token,
      }
    })
    .catch(error => {
      console.log(error)
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
export async function currentAccount() {
  let authorized1 = false
  return axios
    .get(`/apiV1user/current_user/`, {
      type: 'json',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      authorized1 = true
      const { data } = response
      return {
        id: data.id,
        authorized: authorized1,
        contact: data.contact,
        role: data.roles,
        email: data.email,
        token: localStorage.getItem('token'),
        // avatar: data.image,
      }
    })
    .catch(error => {
      notification.warning({
        message: 'Invalid Token Id or Session is Lost',
        description: error.message,
      })
    })
}
export async function logout() {
  localStorage.removeItem('token')
}
